export const itemsSelectSales: Record<"value" | "label", string>[] = [
  {
    value: "Скидка на квартиру от ГК «Гранель»",
    label: "Скидка на квартиру от ГК «Гранель»",
  },
  {
    value: "Скидка на путешествие от TEZ TOUR",
    label: "Скидка на путешествие от TEZ TOUR",
  },
  {
    value: "Скидка на электронику Techport.ru",
    label: "Скидка на электронику Techport.ru",
  },
]
