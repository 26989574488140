import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/100Cashback"
import { Questions } from "../components/Questions"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { Partners } from "../components/PartnersNew"
import { NewFooter } from "../components/NewFooter"

import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { itemsSelectSales } from "../helpers/pageConstants/100cashback"
import { QUESTIONS_FOR_100_CASHBACK } from "../components/Questions/helpers"
import { InfoCards } from "../components/InfoCardsHalva"
import { SALES_CARDS } from "../components/InfoCardsHalva/helpers"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const bannerTitle = (
    <>
      Обменивайте 100% <br /> кэшбэка на скидку
    </>
  )
  const bannerDescr = (
    <ul>
      <li>— на квартиру</li>
      <li>— на технику</li>
      <li>— на путешествие</li>
    </ul>
  )

  const partnersTitle = <>Более 250 000 магазинов-партнеров</>

  const partnersDescr = (
    <>
      Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца
      срок рассрочки на новые покупки устанавливает партнер:
    </>
  )

  const formTitle = (
    <>
      Оформите карту сейчас – <br /> получите подарок!
    </>
  )

  const infoCardsTitle = <>Возвращаем 100% кэшбэк за покупки по «Халве»!</>

  const infoCardsDescr = (
    <>
      Получайте 100% кэшбэк за покупки в любых магазинах-партнёрах в рассрочку и обменивайте его на
      скидку у партнёров акции:
    </>
  )

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader />
      <Banner title={bannerTitle} description={bannerDescr} orderNum="1" />
      <InfoCards
        description={infoCardsDescr}
        variant="cashback100"
        title={infoCardsTitle}
        cards={SALES_CARDS}
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как принять участие в акции"
        variant="cashback100"
        orderNum="3"
      />
      <FourAdvantagesPoints variant="newYear" orderNum="4" withTitle />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <FormPKW
        isGreenApprove
        dataLayerName="shortPersonalForm"
        longTitle
        additionalEventInDataLayer
        withTimer
        formBtnText="Оформить карту"
        orderNum="6"
        emptyForm
        onlyPKWPage
        hasSelect
        selectItems={itemsSelectSales}
        selectTitle="Обменять кэшбэк"
        title={formTitle}
      />
      <Partners
        variant="cashBack100"
        title={partnersTitle}
        subTitle={partnersDescr}
        darkColor
        orderNum="7"
      />
      <BottomCta variant="pkwRedesign" BgImg={RefinSeoBgImg} orderNum="8" />
      <Questions questionList={QUESTIONS_FOR_100_CASHBACK} orderNum="9" />
      <NewFooter ligal={ligal} orderNum="10" />
    </Layout>
  )
}
export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/100cashback/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
