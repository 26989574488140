// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--Zr2ur";
export var btnBlock = "banner-module--btnBlock--aqlH-";
export var container = "banner-module--container--Ustid";
export var hideMobBlock = "banner-module--hideMobBlock--hRIUA";
export var img = "banner-module--img--hzNgD";
export var mobBtn = "banner-module--mobBtn--3ayUM";
export var secondContainer = "banner-module--secondContainer--2vKwK";
export var section = "banner-module--section--cS1ab";
export var someDate = "banner-module--someDate--Sv1Xs";
export var text = "banner-module--text--Louee";